import React from "react";
import {
    ButtonCompContainer,
    ButtonCompIcon,
    ButtonCompRow,
    ButtonCompText,
    ButtonCompWrapper
} from "./ButtonCompStyled";
import Link from "next/link";
import {useAmp} from "next/amp";

export const config = {amp: 'hybrid'}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [elemName: string]: any;
        }
    }
}


type IProps = {
    children: React.ReactNode;
    url?: string;
    isActive?: boolean;
    fullWidth?: boolean;
    uppercase?: boolean;
    leftIcon?: string;
    rightIcon?: string;
    backgroundColor?: string;
    onClick?: React.MouseEventHandler;
    visible?: boolean;
    textColor?: boolean;
    margin?: boolean;
    career?: boolean;
    borderColors?: string;
    className?: string;
    loading?: boolean;
    dataTestId?: string;
    pl?: boolean;
};

const ButtonComp = ({
                        children,
                        url = '#',
                        leftIcon = '',
                        rightIcon = '',
                        backgroundColor = '',
                        isActive = true,
                        fullWidth = false,
                        uppercase = false,
                        onClick,
                        visible = false,
                        textColor = false,
                        margin = true,
                        career = false,
                        borderColors = '',
                        className = '',
                        loading = false,
                        pl = false,
                        dataTestId = ''
                    }: IProps) => {

    const isAmp = useAmp();

    return (
            <ButtonCompContainer onClick={onClick} visible={visible}
                                 fullWidth={fullWidth} margin={margin} career={career} data-testid={dataTestId}>
                <ButtonCompWrapper
                    backgroundColor={backgroundColor}
                    isActive={isActive}
                    href={url}
                    onClick={url === '#!' ? (e) => {
                            e.preventDefault();
                    } : undefined }
                    borderColors={borderColors}
                    style={{
                        cursor: loading ? 'not-allowed' : 'pointer',
                        ...(pl && { paddingLeft: '29px' }),
                }}
                >
                    <ButtonCompRow data-testid={`${dataTestId}-row`} pl={pl}>

                    {(leftIcon !== '') ? (
                        isAmp ? (
                            <amp-img
                                src={`${leftIcon}`}
                                width="26"
                                height="26"
                                layout="fixed"
                                alt="Pembe Panjur Facebook Ile Giriş Yap"
                                data-testid={`${dataTestId}-leftIcon`}
                            />
                        ) : (
                            <ButtonCompIcon src={`${leftIcon}`} alt="Pembe Panjur Facebook Ile Giriş Yap" width={26}
                                            height={26} data-testid={`${dataTestId}-leftIcon`}/>
                        )
                    ) : null}
                    {children ? (
                        <ButtonCompText textColor={textColor} uppercase={uppercase} className={className}>
                            {children}
                        </ButtonCompText>
                    ) : null}
                    {(rightIcon !== '') ? (
                        <ButtonCompIcon src={`${rightIcon}`} alt="" data-testid={`${dataTestId}-rightIcon`}/>
                    ) : null}
                </ButtonCompRow>
            </ButtonCompWrapper>
        </ButtonCompContainer>
    )
}

export default ButtonComp;

import SweetFailed from "./failed";

export function GenericErrorCaller(
    response : any = {}
){

    let errorMessage = 'Bilinmeyen bir hata oluştu.'

    if(
        response &&
        response.data &&
        response.data.error_detail_message &&
        typeof response.data.error_detail_message === 'string' &&
        response.data.error_detail_message !== ''
    ){
        errorMessage = response.data.error_detail_message;
    }

    SweetFailed(
        errorMessage,
        'Hata!'
    );

}

export function GenericErrorCallerForCatch(
    error : any
){

    let errorMessage = 'Bilinmeyen bir hata oluştu.'

    if(
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error_detail_message &&
        typeof error.response.data.error_detail_message === 'string' &&
        error.response.data.error_detail_message !== ''
    ){
        errorMessage = error.response.data.error_detail_message;
    }

    SweetFailed(
        errorMessage,
        'Hata!'
    );

}

export function handleError(error: any) {
    let errorMessage = 'Bilinmeyen bir hata oluştu.';

    if (
        error &&
        error.error_detail_message
    ) {
        errorMessage = error.error_detail_message;
    } else if (
        error &&
        error.data &&
        error.data.error_detail_message
    ) {
        errorMessage = error.data.error_detail_message;
    } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error_detail_message &&
        typeof error.response.data.error_detail_message === 'string' &&
        error.response.data.error_detail_message !== ''
    ) {
        errorMessage = error.response.data.error_detail_message;
    }

    SweetFailed(errorMessage, 'Hata!');
}
